import { useQueries } from '@tanstack/react-query'
import { GET } from '../../../fixrateCity/src/utils/networkCallApi'
import { EChartsOption } from '../echarts'
import EChart from '../EChart'
import { PURPLE, SIGNAL_YELLOW, SPRING_GREEN } from '../../../../colors/colors'
import { Box, Typography } from '@mui/material'
import CurrencyOutput from '../../../fixrateCity/src/components/CurrencyOutput/CurrencyOutput'
import { Period } from '../../KPIDashboard'

type IncomeData = {
  WEEK: {
    value: number
    change: number
    budget: number
  }
  MONTH: {
    value: number
    change: number
    budget: number
  }
  YTD: {
    value: number
    budget: number
  }
}

export const IncomeSeriesBarChart = ({ token, period }: { token: string, period: Period }) => {

  const results = useQueries({
    queries: [
      {
        queryKey: ['income-series' + period, token],
        queryFn: () => GET('/api/stagg/kpi/income-series?range=' + period, token),
      },
      {
        queryKey: ['income', token],
        queryFn: () => GET('/api/stagg/kpi/income', token),
      },
    ],
  })

  let incomeData: IncomeData
  let incomeValue: number = 0
  let budgetValue: number = 0
  let incomeChange: number = 0
  let diffBudgetIncome: number = 0
  let percentOfBudget: string = ''

  let incomeSeries

  let deposits: number[]
  let banks: number[]
  let funds: number[]
  let budget: number[]

  let bankVolumeTotal: number
  let fundVolumeTotal: number
  let depositsVolumeTotal: number
  let budgetVolumeTotal: number

  const periodMapping: { [key in Period]: string } = {
    '1W': 'WEEK',
    '1M': 'MONTH',
    YTD: 'YTD',
  }
  const periodText = {
    '1W': 'Siste 7 dager',
    '1M': 'Siste 30 dager',
    YTD: 'Hittil i år',
  }
  const periodString = periodMapping[period]

  if (!results[0].isPending && results[0].isSuccess && !results[1].isPending && results[1].isSuccess) {
    incomeSeries = results[0]
    incomeData = results[1].data
    const dataToMap = results[0].data[0].series
    const series = dataToMap.reduce(
      (result, data) => ({
        ...result,
        [data.name]: data.values,
      }),
      {}
    )
    deposits = series.deposits.map(value => value[1]?.toFixed(0) ?? 0)
    banks = series.bank.map(value => value[1]?.toFixed(0) ?? 0)
    funds = series.fund.map(value => value[1]?.toFixed(0) ?? 0)
    budget = series.budget.map(value => value[1]?.toFixed(0) ?? 0)

    depositsVolumeTotal = deposits.reduce((a, b) => +a + +b, 0)
    bankVolumeTotal = banks.reduce((a, b) => +a + +b, 0)
    fundVolumeTotal = funds.reduce((a, b) => +a + +b, 0)
    budgetVolumeTotal = budget.reduce((a, b) => +a + +b, 0)


    incomeValue = incomeData[periodString].value.toFixed(0)
    budgetValue = incomeData[periodString].budget.toFixed(0)
    incomeChange = incomeData[periodString].change ? incomeData[periodString].change.toFixed(0) : 0
    diffBudgetIncome = budgetValue - incomeValue

    percentOfBudget = ((incomeValue / budgetValue) * 100).toFixed(0)


  }

  type BarLabelOption = NonNullable<echarts.BarSeriesOption['label']>;

  const labelOption: BarLabelOption = {
    show: true,
    position: 'outside',
    distance: 20,
    align: 'center',
    verticalAlign: 'middle',
    rotate: 0,
    formatter: (params) => {
      const value = CurrencyOutput.formatDecimal(params.value);
      return `{value|${value}}`;
    },
    fontSize: 16,
    rich: {
      name: {}
    }
  };

  const labelOptionStack: BarLabelOption = {
    show: true,
    position: 'outside',
    distance: 20,
    align: 'center',
    verticalAlign: 'middle',
    rotate: 0,
    formatter: (params) => {
      const value = CurrencyOutput.formatDecimal(depositsVolumeTotal + bankVolumeTotal + fundVolumeTotal);
      return `{value|${value}}`;
    },
    fontSize: 16,
    rich: {
      name: {}
    }
  };

  let emphasisStyle = {
    itemStyle: {
      shadowBlur: 10,
      shadowColor: 'rgba(0,0,0,0.3)'
    }
  };

  const incomeSeriesOptions: EChartsOption = {
    tooltip: {},
    legend: {
      data: ['Bank', 'Innskudd', 'Fond', 'Budsjett'],
      right: '5%',
      top: 0,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
        data: [periodText[period]],
      }
    ],
    yAxis: [
      {
        type: 'value',

      }
    ],
    grid: {
            bottom: '20rem',
            left: '80rem',
            right: '20rem',
        },

    series: [
      {
        name: 'Bank',
        type: 'bar',
        barGap: 0,
        stack: 'Bank',
        color: PURPLE[50],

        label: labelOption,
        emphasis: emphasisStyle,
        data: [bankVolumeTotal]
      },
      {
        name: 'Innskudd',
        type: 'bar',
        stack: 'Innskudd',
        color: PURPLE[300],
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: [depositsVolumeTotal]
      },
      {
        name: 'Fond',
        type: 'bar',
        color: SPRING_GREEN[500],
        stack: 'Fund',
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: [fundVolumeTotal]
      },
      {
        name: 'Total',
        type: 'bar',
        stack: 'Total',
        color: PURPLE[50],
        tooltip: {
          show: false
        },
        emphasis: {
          disabled: true
        },
        data: [bankVolumeTotal]
      },
      {
        name: 'Total',
        type: 'bar',
        stack: 'Total',
        color: PURPLE[300],
        tooltip: {
          show: false
        },
        emphasis: {
          disabled: true
        },
        data: [depositsVolumeTotal]
      },
      {
        name: 'Total',
        type: 'bar',
        stack: 'Total',
        color: SPRING_GREEN[500],
        label: labelOptionStack,
        tooltip: {
          show: false
        },
        emphasis: {
          disabled: true
        },
        data: [fundVolumeTotal]
      },
      {
        name: 'Budsjett',
        type: 'bar',
        color: SIGNAL_YELLOW[600],
        stack: 'Budget',
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: [budgetVolumeTotal]
      }
    ]
  };

  const incomeTrend = (value: number) => {
    if (value === 0) return ''
    if (value.toString().length > 6)
      return `+ ${CurrencyOutput.formatMillionWithDecimals(value)}`

    return `${CurrencyOutput.formatDecimal(value)}`
  }

  return (
    <>
      <Typography variant='h2' fontWeight={800} display={'inline-flex'}>
        {incomeValue.toString().length > 6 ? CurrencyOutput.formatMillionWithDecimals(incomeValue) : CurrencyOutput.formatDecimal(incomeValue)}
        <Box sx={{ display: 'inline-flex' }}>
          <Typography sx={{ display: 'flex', pl: '1rem', color: SPRING_GREEN[500], alignItems: 'center', height: '100%' }} variant='h5'>
            {incomeTrend(incomeChange)}
          </Typography>
        </Box>
      </Typography>
      <EChart loading={incomeSeries?.isPending} options={incomeSeriesOptions} error={incomeSeries?.error} style={{ height: '28vh' }} />
    </>
  )
}